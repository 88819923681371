import React from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import Img1 from "../../assets/convert-logo.svg";

import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavMainMenu,
    NavMainItem,
    NavMainLinks,
    NavLogoImg,
} from "./NavbarElements.js";

function Navbar() {
    return (
        <IconContext.Provider value={{ color: "#fff" }}>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">
                        <NavLogoImg src={Img1} />
                    </NavLogo>
                    <MobileIcon>
                        <FaBars />
                    </MobileIcon>
                    <NavMainMenu>
                        <NavMainItem>
                            <NavMainLinks to="/">
                                <NavMenu>
                                    <NavItem>
                                        <NavLinks to="/">Sobre</NavLinks>
                                    </NavItem>
                                    <NavItem>
                                        <NavLinks to="/">Produtos</NavLinks>
                                    </NavItem>
                                    <NavItem>
                                        <NavLinks to="/">Contato</NavLinks>
                                    </NavItem>
                                </NavMenu>
                            </NavMainLinks>
                        </NavMainItem>

                        <NavMainItem></NavMainItem>
                    </NavMainMenu>
                    <NavBtn></NavBtn>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    );
}

export default Navbar;
