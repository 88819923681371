export const homeObj = {
    id: "team",
    buttonPath: "/team",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "ENGENHARIA CONVERT",
    headline: "TRJ TECHNOLOGY",
    description:
        "O rastreador de eixo único TRJ adota uma tecnologia eletromecânica para acompanhar a posição solar Leste-Oeste de todos os dias em um eixo de rotação horizontal Norte-Sul, para posicionar os painéis fotovoltaicos sempre no ângulo perfeito com os raios solares.",
    buttonLabel: "Saber mais",
    imgStart: true,
    img: require("../../assets/0.svg").default,
    alt: "Teste svg2",
    dark: true,
    primary: true,
    darkText: false,
};