import React from "react";
import {
    Button,
    Container,
    Content,
    HeroBg,
    Left,
    TextArea,
    Title,
    VideoBg,
} from "./HeroElements";
import Video from "../../assets/panelsVideo2.mp4";

function Hero() {
    return (
        <Container>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
            </HeroBg>
            <Content>
                <Left>
                    <Title>
                        O Futuro chegou
                        <br />
                    </Title>
                    <TextArea>
                        Com mais de 10 anos de experiência na indústria solar e
                        35 anos no setor de energia, a equipe da Convert
                        continuará liderando o negócio. <br />
                        <br /> "A oportunidade de fazer parceria com a Valmont
                        como líder da indústria no mercado de serviços públicos
                        energizou a equipe da Convert Italia"
                        <br /> disse Giuseppe Moro.
                        <br />
                        <br />
                        Venha conhecer a nossa técnologia
                    </TextArea>
                    <Button href="https://google.com">
                        <span> Conhecer</span>
                    </Button>
                </Left>
            </Content>
        </Container>
    );
}

export default Hero;
