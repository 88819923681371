import React from "react";
import Img1 from "../../assets/1.png";
import Img2 from "../../assets/2.png";
import Img3 from "../../assets/3.png";

import {
    CardH1,
    CardWrapper,
    CardIcon,
    CardInfo,
    CardH2,
    CardP,
    CardContainer,
    CardSubSpan,
} from "./CardSectionElements";

function CardSection() {
    return (
        <CardContainer id="projects">
            <CardH1>
                <CardSubSpan> </CardSubSpan>
            </CardH1>
            <CardWrapper>
                <CardInfo>
                    <CardIcon src={Img1} />
                    <CardH2>teste</CardH2>
                    <CardP>teste</CardP>
                </CardInfo>
                <CardInfo>
                    <CardIcon src={Img2} />
                    <CardH2>teste </CardH2>
                    <CardP>
                        Completamente balanceada e modular, a estrutura do TRJ
                        não requer pessoal especializado para instalação e
                        montagem.
                    </CardP>
                </CardInfo>
                <CardInfo>
                    <CardIcon src={Img3} />
                    <CardH2>teste</CardH2>
                    <CardP>
                        Solução “bruta independente”, com motor AC de anel duplo
                        de proteção contra poeira independente exclusivo.
                    </CardP>
                </CardInfo>
            </CardWrapper>
        </CardContainer>
    );
}

export default CardSection;
