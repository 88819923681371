import React from "react";

import Hero from "../../components/Hero";
import InfoSection from "../../components/InfoSection";
import Navbar from "../../components/Navbar";
import { homeObj } from "../../components/InfoSection/Data";
import CardSection from "../../components/CardSection";

const Home = () => {
    return (
        <>
            <Navbar />
            <Hero />
            <InfoSection {...homeObj} />
            <CardSection />
        </>
    );
};

export default Home;
