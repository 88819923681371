import styled from "styled-components";
import BgImg from "../../assets/background.jpg";

export const Container = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 975px;
    position: relative;
    z-index: 1;

    :before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
        z-index: 2;
    }

`;
export const Content = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const Left = styled.div`
    padding-right: 390px;
    padding-bottom: 120px;
`;
export const Title = styled.p`
    font-size: 55px;
    color: #fff;
    font-weight: 400;
`;
export const TextArea = styled.p`
    width: 650px;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    margin-top: 58px;
`;

export const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    margin-top: 58px;
    width: 371px;
    height: 71px;
    line-height: 71px;
    font-size: 22px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(90deg, #204e6a, #19445e);
    text-decoration: none;
    box-shadow: 0 15px 14px rgb(0 42 177 / 12%);
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;
